import React, {useEffect, useState } from "react";
  

import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Navbar from "../includes/Navbar";
import Pagetitle from "../includes/Pagetitle";

import Footer from "../includes/Footer";

import General from "./General";
import Smtp from "./Smtp";
import Social from "./Social";
import Seo from "./Seo";
import Footersettings from "./Footersettings";
import Paymentgateway from "./Paymentgateway";

import Mapapisettings from "./Mapapisettings";
import Taxsettings from "./Taxsettings";

import Commissionsettings from "./Commissionsettings";



import Googlesettings from "./Googlesettings";


  import { checkpermissionredirect} from './../Api.js';

import {    
    Allowsettings_view 
} from './../Permissions.js';

 
const Settings = () => {
 


    useEffect(() => { 
 
    checkpermissionredirect(Allowsettings_view)



  }, []);


  return (

    
      <div id="app">
            <Header  title="Settings" />

        <Sidebar activePage="Settings" />
        <div id="main">
        <Navbar />


            <div className="main-content container-fluid">
                
                <Pagetitle  activePage="Settings"  />


             



                        <div className="row match-height">
                        <div className="col-md-9 col-12 mx-auto">
                        <div className="card">
                        <div className="card-header">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                        <a className="nav-link active card-title" id="general-tab" data-bs-toggle="tab" href="#general" role="tab" aria-controls="home"
                        aria-selected="true">General</a>
                        </li>
                        <li className="nav-item" role="presentation">
                        <a className="nav-link card-title" id="smtp-tab" data-bs-toggle="tab" href="#smtp" role="tab" aria-controls="profile"
                        aria-selected="false">SMTP</a>
                        </li>
                        <li className="nav-item" role="presentation">
                        <a className="nav-link card-title" id="social-tab" data-bs-toggle="tab" href="#social" role="tab" aria-controls="contact"
                        aria-selected="false">Social Media</a>
                        </li>

                         <li className="nav-item" role="presentation">
                        <a className="nav-link card-title" id="seo-tab" data-bs-toggle="tab" href="#seo" role="tab" aria-controls="contact"
                        aria-selected="false">Seo</a>
                        </li>


                      
                         <li className="nav-item" role="presentation">
                        <a className="nav-link card-title" id="footer-tab" data-bs-toggle="tab" href="#footer_tab" role="tab" aria-controls="contact"
                        aria-selected="false">Footer</a>
                        </li> 

                          <li className="nav-item" role="presentation">
                        <a className="nav-link card-title" id="paymentgateway-tab" data-bs-toggle="tab" href="#paymentgateway" role="tab" aria-controls="contact"
                        aria-selected="false">Payment Gateway</a>
                        </li>



                          <li className="nav-item" role="presentation">
                        <a className="nav-link card-title" id="mapapisettings-tab" data-bs-toggle="tab" href="#mapapisettings" role="tab" aria-controls="contact"
                        aria-selected="false">Google Map Api</a>
                        </li>

                          <li className="nav-item" role="presentation">
                        <a className="nav-link card-title" id="taxsettings-tab" data-bs-toggle="tab" href="#taxsettings" role="tab" aria-controls="contact"
                        aria-selected="false">Tax</a>
                        </li>



                         <li className="nav-item" role="presentation">
                        <a className="nav-link card-title" id="commissionsettings-tab" data-bs-toggle="tab" href="#commissionsettings" role="tab" aria-controls="contact"
                        aria-selected="false">Platform Charges</a>
                        </li>


                           {/*  <li className="nav-item" role="presentation">
                        <a className="nav-link card-title" id="googlesettings-tab" data-bs-toggle="tab" href="#googlesettings" role="tab" aria-controls="contact"
                        aria-selected="false">Firebase Web Server Key</a>
                        </li>*/ }


                        </ul>


                        </div>
                        <div className="card-content">
                        <div className="card-body">
                        <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="home-tab">
                        <General />
                        </div>

                        <div className="tab-pane fade    " id="smtp" role="tabpanel" aria-labelledby="home-tab">
                        <Smtp />
                        </div>


                        <div className="tab-pane fade    " id="social" role="tabpanel" aria-labelledby="home-tab">
                        <Social />
                        </div>

                         <div className="tab-pane fade    " id="seo" role="tabpanel" aria-labelledby="home-tab">
                        <Seo />
                        </div>

                        <div className="tab-pane fade    " id="footer_tab" role="tabpanel" aria-labelledby="home-tab">
                        <Footersettings />
                        </div>

                        <div className="tab-pane fade    " id="paymentgateway" role="tabpanel" aria-labelledby="home-tab">
                        <Paymentgateway />
                        </div>


                            <div className="tab-pane fade    " id="googlesettings" role="tabpanel" aria-labelledby="home-tab">
                        <Googlesettings />
                        </div>


                        <div className="tab-pane fade    " id="mapapisettings" role="tabpanel" aria-labelledby="home-tab">
                        <Mapapisettings />
                        </div>


                        <div className="tab-pane fade    " id="taxsettings" role="tabpanel" aria-labelledby="home-tab">
                        <Taxsettings />
                        </div>


                        <div className="tab-pane fade    " id="commissionsettings" role="tabpanel" aria-labelledby="home-tab">
                        <Commissionsettings />
                        </div>


                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>

    
 

 


                <Footer />
            </div>


         </div>

      </div>
 
 
  );
};
export default Settings;