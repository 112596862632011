import React, { useState, useEffect } from "react";

import { baseUrl, Url, CommonNotify } from './../Api.js';

import Chart from 'react-apexcharts';

import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Navbar from "../includes/Navbar";
import Pagetitle from "../includes/Pagetitle";
import 'font-awesome/css/font-awesome.min.css';


import Footer from "../includes/Footer";

const Dashboard = () => {
    const [usersCount, setusersCount] = useState(1);
    const [vendorsCount, setvendorsCount] = useState(1);
    const [userdatavalues, setuserdatavalues] = useState(0);
    const [orderdatavalues, setorderdatavalues] = useState(0);
    const [vendordatavalues, setvendordatavalues] = useState(0);

    const [clientdata, setclientdata] = useState([]);
    const [orderdata, setorderdata] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

   
    const fetchVendorCount = async () => {
        try {
            const response = await fetch('http://localhost:5090/getvendors', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                if (data.success) {
                    setvendorsCount(data.count); 
                } else {
                    setError("Failed to fetch vendor count");
                }
            } else {
                setError("Error fetching vendor data");
            }
        } catch (error) {
            setError("Error fetching vendor data");
        }
    };


   

   
    useEffect(() => {
        fetchVendorCount();
    }, []);

    return (
        <div id="app">
            <Header title="Dashboard" />
            <Sidebar activePage="Dashboard" />
            <div id="main">
                <Navbar />
                <div className="main-content container-fluid">
                    <Pagetitle activePage="Dashboard" />
                    <section className="section">
                        <div className="row mb-2">
                            <div className="col-12 col-md-3">
                                <div className="card card-statistic">
                                    <div className="card-body p-0">
                                        <div className="d-flex flex-column">
                                            <div className='px-3 py-3 d-flex justify-content-between'>
                                                <h3 className='card-title'> <i className="fa fa-user"></i> Customers</h3>
                                                <div className="card-right d-flex align-items-center dashboard-card">
                                                    <p>{usersCount}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-3">
                                <div className="card card-statistic">
                                    <div className="card-body p-0">
                                        <div className="d-flex flex-column">
                                            <div className='px-3 py-3 d-flex justify-content-between'>
                                                <h3 className='card-title'> <i className="fa fa-user"></i> Vendors</h3>
                                                <div className="card-right d-flex align-items-center dashboard-card">
                                                    <p>{vendorsCount}</p> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-12 col-md-3">
    <div className="card card-statistic">
        <div className="card-body p-0">
            <div className="d-flex flex-column">
                <div className='px-3 py-3 d-flex justify-content-between'>
                    <h3 className='card-title'> <i className="fa fa-users"></i> Registrations Today</h3>
                    <div className="card-right d-flex align-items-center dashboard-card">
                        <p>{newRegistrationsCount}</p> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> */}

                        </div>
                    </section>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
