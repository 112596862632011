import React, { useEffect, useState } from "react";
import {
   Link,
   useNavigate
} from "react-router-dom";
import { baseUrl, Url, ModalPopupDelete, DeleteNotify, checkpermissionredirect } from './../../Api.js';


import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";

//import Pagination from "../../includes/Pagination";
import {
   Allowbookings_view
} from './../../Permissions.js';


const Userlist = () => {





   const [bookingsList, setbookingsList] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [recordsPerPage] = useState(10);

   //const [notification, setnotification] = useState("");
   //const [notificationtype, setnotificationtype] = useState("");
   const [nPages, setnPages] = useState(1);
   const [pageNumbers, setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));

   //const nPages = Math.ceil(bookingsList.length / recordsPerPage)

   const goToNextPage = () => {
      if (currentPage !== nPages) {
         setCurrentPage(currentPage + 1)
         GetBookings()
      }
   }
   const goToPrevPage = () => {
      if (currentPage !== nPages) {
         setCurrentPage(currentPage - 1)
         GetBookings()

      }
   }


   const goToPage = (value) => {
      setCurrentPage(value)

      GetBookings(value)






   }

   const GetBookings = async (value) => {

      // let formData = new FormData()
      //  formData.append('currentpage', currentPage)
      //   formData.append('perpage', recordsPerPage)

      value = (typeof value !== 'undefined') ? value : currentPage;

      var postdata = { 'currentpage': value, 'perpage': recordsPerPage };

      const response = await fetch(Url + "getbookings", {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({ postdata }),

      }).then((response) => response.json())
         .then((data) => {
            if (data.success) {
               setbookingsList(data.data);
               if (data.count > 1) {
                  //setnPages(data.count / recordsPerPage);

                  setpageNumbers([...Array(Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1))

               }

            } else {

            }


         })



   };


   const DeleteConfirm = async (id) => {

      if (window.confirm("Are you sure?")) {
         var postdata = { 'id': id };
         const response = await fetch(Url + "deleteuser", {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
            },
            body: JSON.stringify({ postdata }),

         }).then((response) => response.json())
            .then((data) => {
               if (data.success) {
                  DeleteNotify()
               } else {
                  DeleteNotify(data.message);
               }

               GetBookings();


            })

      }




   };



   // const indexOfLastRecord = currentPage * recordsPerPage;
   //  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
   //  const currentRecords = bookingsList.slice(indexOfFirstRecord, indexOfLastRecord);
   //  const nPages = Math.ceil(bookingsList.length / recordsPerPage)



   //pagination part



   useEffect(() => {
      GetBookings();

      checkpermissionredirect(Allowbookings_view)



   }, []);

   //pagination part

   return (
      <div id="app">
         <Header title="Bookings List" />

         <Sidebar activePage="Bookings" activeChildPage="List" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">

               <Pagetitle activePage="Bookings" />

               {ModalPopupDelete()}

               <section className="section">
                  <div className="card">
                     <div className="card-header">
                        <span>Bookings</span>



                     </div>
                     <div className="card-body">


                        <table className='table table-striped' id="table1">
                           <thead>
                              <tr>
                                 <th>Date</th>
                                 <th>Booking Id</th>
                                 <th>Customer Name</th>
                                 <th>Amount</th>
                                 <th>Status</th>
                                 <th>Action</th>
                              </tr>
                           </thead>
                           {bookingsList.length > 0 ?

                              <tbody>

                                 {





                                    bookingsList.map((booking) => (

                                       <tr>
                                          <td>{booking.date}</td>
                                          <td>{booking.ordernumber}</td>
                                          <td>{booking.customername}</td>
                                          <td>{booking.amount}</td>

                                          <td>{booking.status}</td>


                                          <td>{booking.status}</td>



                                       </tr>

                                    ))


                                 }

                              </tbody> : <tbody><tr> <td colspan="6" align="center" >No Records Found </td> </tr></tbody>}
                        </table>
                     </div>
                  </div>

                  {pageNumbers.length > 1 ?

                     <nav>
                        <ul className='pagination pagination-primary justify-content-center'>
                           <li className="page-item prev-paging-single">
                              <a className="page-link"
                                 onClick={goToPrevPage}
                                 href='#'>
                                 Previous
                              </a>
                           </li>
                           {pageNumbers.map(pgNumber => (
                              <li key={pgNumber}
                                 className={`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
                                 <a onClick={(e) => goToPage(pgNumber)}
                                    className='page-link'
                                    href='#'>
                                    {pgNumber}
                                 </a>
                              </li>
                           ))}
                           <li className="page-item paging-next-single">
                              <a className="page-link"
                                 onClick={goToNextPage}
                                 href='#'>
                                 Next
                              </a>
                           </li>
                        </ul>
                     </nav> : ''}

               </section>

               <Footer />
            </div>


         </div>

      </div>


   );
};
export default Userlist;