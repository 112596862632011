import React from "react";
import {  Routes, Route } from "react-router-dom";


/*
import FrontendLogin from "./frontend/auth/Login";
import FrontendRegister from "./frontend/auth/Register";

import FrontendRegisterTeacher from "./frontend/auth/Registerteacher";

import FrontendResetpassword from "./frontend/auth/Resetpassword";
import FrontendLogout from "./frontend/auth/Logout";
import FrontendAbout from "./frontend/cms/About"; 

import FrontendTermsConditions from "./frontend/cms/Terms"; 

import FrontendRefundPolicy from "./frontend/cms/Refund"; 

import FrontendPrivacyPolicy from "./frontend/cms/Privacy"; 


import FrontendContactUs from "./frontend/cms/Contact"; 
import FrontendProfile from "./frontend/profile/Profile";


*/
 import FrontendHome from "./frontend/pages/Home";

  import FrontendComingsoon from "./frontend/pages/Comingsoon";

 

import FrontendLogin from "./frontend/auth/Login";

import FrontendRegister from "./frontend/auth/Register";
import FrontendForgotpassword from "./frontend/auth/Forgotpassword";
import FrontendResetpassword from "./frontend/auth/Resetpassword";

  

 





 
 import FrontendMyprofile from "./frontend/profile/Myprofile";


 import FrontendEditprofile from "./frontend/profile/Editprofile";

  import FrontendChangepassword from "./frontend/profile/Changepassword";
  import FrontendLogout from "./frontend/profile/Logout";



  import FrontendRefer from "./frontend/user/Refer";
  import FrontendChannels from "./frontend/user/Channels";

  import FrontendBilling from "./frontend/user/Billing";



  import FrontendBlog from "./frontend/pages/Blog";
    import FrontendBlogDetail from "./frontend/pages/BlogDetail";


  import FrontendFaq from "./frontend/cms/Faq";


  import FrontendContactus from "./frontend/cms/Contactus";


    import FrontendPrivacypolicy from "./frontend/cms/Privacypolicy";
    import FrontendAboutus from "./frontend/cms/Aboutus";
    import FrontendTermsConditions from "./frontend/cms/Termsconditions";

    import FrontendRefundPolicy from "./frontend/cms/Refundpolicy";
        import FrontendLicenseagreement from "./frontend/cms/Licenseagreement";



//Vendor

import VendorLogin from "./vendor/auth/Login";
import VendorLogout from "./vendor/auth/Logout";
import  VendorForgotpassword from "./vendor/auth/Forgotpassword";
import  VendorResetpassword from "./vendor/auth/Resetpassword";



 import VendorChangepassword from "./vendor/profile/Changepassword";
import VendorProfile from "./vendor/profile/Profile";
import VendorRegister from './vendor/auth/Register';


import VendorCategories from "./vendor/pages/Mycategories";

import VendorEarnings from "./vendor/pages/Myearnings";
import VendorJobs from "./vendor/pages/Myjobs";
import VendorServices from "./vendor/pages/Myservices";


import VendorDashboard from "./vendor/pages/Dashboard";




 //Admin

 
import AdminLogin from "./admin/auth/Login";
import AdminLogout from "./admin/auth/Logout";
import  AdminForgotpassword from "./admin/auth/Forgotpassword";
import  AdminResetpassword from "./admin/auth/Resetpassword";



import AdminSettings from "./admin/profile/Settings";
import AdminChangepassword from "./admin/profile/Changepassword";
import AdminProfile from "./admin/profile/Profile";


import AdminDashboard from "./admin/pages/dashboard";


import AdminadminUserlist from "./admin/pages/adminusers/List";
import AdminadminUseradd from "./admin/pages/adminusers/Add";

import AdminRolelist from "./admin/pages/roles/List";
import AdminRoleadd from "./admin/pages/roles/Add";

import AdminCmslist from "./admin/pages/cms/List";
import AdminCmsadd from "./admin/pages/cms/Add";


import AdminEmailtemplateslist from "./admin/pages/emailtemplates/List";
import AdminEmailtemplatesadd from "./admin/pages/emailtemplates/Add";
  
 
import AdminEnquirieslist from "./admin/pages/enquiries/List";

import AdminSubscriberslist from './admin/pages/Subscribers/List';
 
 

 import AdminUserlist from "./admin/pages/users/List";
import AdminUseradd from "./admin/pages/users/Add";
import AdminUseredit from "./admin/pages/users/Add";

 import AdminVendorlist from "./admin/pages/vendors/List";
import AdminVendoradd from "./admin/pages/vendors/Add";
import AdminVendoredit from "./admin/pages/vendors/Add";


 import AdminFaqlist from "./admin/pages/faq/List";
import AdminFaqadd from "./admin/pages/faq/Add";
import AdminFaqedit from "./admin/pages/faq/Add";

 import AdminFaqgrouplist from "./admin/pages/faq/Listgroup";
import AdminFaqgroupadd from "./admin/pages/faq/Addgroup";
import AdminFaqgroupedit from "./admin/pages/faq/Addgroup";



 import AdminSliderlist from "./admin/pages/sliders/List";
import AdminSlideradd from "./admin/pages/sliders/Add";
import AdminSlideredit from "./admin/pages/sliders/Add";




 import AdminBloglist from "./admin/pages/blog/List";
import AdminBlogadd from "./admin/pages/blog/Add";
import AdminBlogedit from "./admin/pages/blog/Add";


 import AdminBlogcategorylist from "./admin/pages/blogcategory/List";
import AdminBlogcategoryadd from "./admin/pages/blogcategory/Add";
import AdminBlogcategoryedit from "./admin/pages/blogcategory/Add";



 import AdminLanguagelist from "./admin/pages/languages/List";
import AdminLanguageadd from "./admin/pages/languages/Add";
 import AdminLanguageAddwords from "./admin/pages/languages/Addwords";




 import AdminCurrencylist from "./admin/pages/currencies/List";
import AdminCurrencyadd from "./admin/pages/currencies/Add";
import AdminCurrencyedit from "./admin/pages/currencies/Add";
 



 import AdminPlanlist from "./admin/pages/plans/List";
import AdminPlanadd from "./admin/pages/plans/Add";
import AdminPlanedit from "./admin/pages/plans/Add";

 import AdminCategorylist from "./admin/pages/categories/List";
import AdminCategoryadd from "./admin/pages/categories/Add";
import AdminCategoryedit from "./admin/pages/categories/Add";



 import AdminSubcategorylist from "./admin/pages/subcategories/List";
import AdminSubcategoryadd from "./admin/pages/subcategories/Add";
import AdminSubcategoryedit from "./admin/pages/subcategories/Add";


 import AdminServicelist from "./admin/pages/services/List";
import AdminServiceadd from "./admin/pages/services/Add";
import AdminServiceedit from "./admin/pages/services/Add";



 import AdminBookingslist from "./admin/pages/bookings/List";

 import AdminNotificationslist from "./admin/pages/notifications/List";
 import AdminNotificationsadd from "./admin/pages/notifications/Add";



function App() { 
  return (

     <div className="  h-screen duration-500">
      
     
        <Routes>

     


      <Route path="/login" element={<FrontendLogin />} />
      <Route path="/signup" element={<FrontendRegister />} />
      <Route path="/forgot-password" element={<FrontendForgotpassword />} />
      <Route path="/reset-password/:id" element={<FrontendResetpassword />} />


    {/*       <Route path="/teacher-register" element={<FrontendRegisterTeacher />} />
           <Route path="/forgot-password" element={<FrontendForgotpassword />} />
          <Route path="/reset-password/:token" element={<FrontendResetpassword />} />
        
               <Route path="/contact-us" element={<FrontendContactUs />} />

         <Route path="/about-us" element={<FrontendAbout />} />
          <Route path="/terms-conditions" element={<FrontendTermsConditions />} />
           <Route path="/privacy-policy" element={<FrontendPrivacyPolicy />} />
            <Route path="/refund-policy" element={<FrontendRefundPolicy />} />

              <Route path="/my-profile/preferences" element={<FrontendProfile />} />
                    


  */ }

              <Route path="/my-profile" element={<FrontendMyprofile />} />
              <Route path="/edit-profile" element={<FrontendEditprofile />} />
              <Route path="/change-password" element={<FrontendChangepassword />} />
                <Route path="/logout" element={<FrontendLogout />} />

               <Route path="/refer" element={<FrontendRefer />} />

              <Route path="/channels" element={<FrontendChannels />} />

                <Route path="/billing" element={<FrontendBilling />} />



               <Route path="/faq" element={<FrontendFaq />} />

                              <Route path="/blog/:id" element={<FrontendBlogDetail />} />

               <Route path="/blog" element={<FrontendBlog />} />



              <Route path="/contact-us" element={<FrontendContactus />} />


              
 


            <Route path="/about-us" element={<FrontendAboutus />} />
            <Route path="/terms-conditions" element={<FrontendTermsConditions />} />
            <Route path="/privacy-policy" element={<FrontendPrivacypolicy />} />
            <Route path="/refund-policy" element={<FrontendRefundPolicy />} />
             <Route path="/license-agreement" element={<FrontendLicenseagreement />} />

            {/* <Route exact path="/" element={<FrontendHome />} />*/}


                        <Route exact path="/" element={<FrontendHome />} />

  {/* Vendor */}



<Route path="/register-vendor" element = {<VendorRegister />} />

      {/* <Route path="/vendor/" element={<VendorLogin />} /> */}
      <Route path="/vendor/login" element={<VendorLogin />} />
      <Route path="/vendor/logout" element={<VendorLogout />} />

      <Route path="/vendor/forgot-password" element={<VendorForgotpassword />} />
      <Route path="/vendor/reset-password/:token" element={<VendorResetpassword />} />


      <Route path="/vendor/dashboard" element={<VendorDashboard />} />
      <Route path="/vendor/my-profile" element={<VendorProfile />} />

      <Route path="/vendor/change-password" element={<VendorChangepassword />} />

      <Route path="/vendor/my-categories" element={<VendorCategories />} />
      <Route path="/vendor/my-earnings" element={<VendorEarnings />} />

      <Route path="/vendor/my-services" element={<VendorServices />} />

      <Route path="/vendor/my-jobs" element={<VendorJobs />} />


  {/* Vendor */}

 
          {/* Admin */}


          <Route path="/umy-admin-portal/" element={<AdminLogin />} />
         <Route path="/umy-admin-portal/login" element={<AdminLogin />} />
         <Route path="/umy-admin-portal/logout" element={<AdminLogout />} />
     
           <Route path="/umy-admin-portal/forgot-password" element={<AdminForgotpassword />} />
           <Route path="/umy-admin-portal/reset-password/:token" element={<AdminResetpassword />} />


           <Route path="/umy-admin-portal/change-password" element={<AdminChangepassword />} />
           <Route path="/umy-admin-portal/settings" element={<AdminSettings />} />
           <Route path="/umy-admin-portal/my-profile" element={<AdminProfile />} />

           <Route path="/umy-admin-portal/dashboard" element={<AdminDashboard />} />

           <Route path="/umy-admin-portal/adminusers" element={<AdminadminUserlist />} />
            <Route path="/umy-admin-portal/adminusers/add" element={<AdminadminUseradd />} />
             <Route path="/umy-admin-portal/adminusers/edit/:id" element={<AdminadminUseradd />} />
             <Route path="/umy-admin-portal/roles" element={<AdminRolelist />} />
              <Route path="/umy-admin-portal/roles/add" element={<AdminRoleadd />} />
             <Route path="/umy-admin-portal/roles/edit/:id" element={<AdminRoleadd />} />

               <Route path="/umy-admin-portal/cms" element={<AdminCmslist />} />
               <Route path="/umy-admin-portal/cms/edit/:id" element={<AdminCmsadd />} />


               <Route path="/umy-admin-portal/emailtemplates" element={<AdminEmailtemplateslist />} />
               <Route path="/umy-admin-portal/emailtemplates/edit/:id" element={<AdminEmailtemplatesadd />} />


            
               <Route path="/umy-admin-portal/users" element={<AdminUserlist />} />
              <Route path="/umy-admin-portal/users/add" element={<AdminUseradd />} />
             <Route path="/umy-admin-portal/users/edit/:id" element={<AdminUseredit />} />

           

   <Route path="/umy-admin-portal/vendors" element={<AdminVendorlist />} />
              <Route path="/umy-admin-portal/vendors/add" element={<AdminVendoradd />} />
             <Route path="/umy-admin-portal/vendors/edit/:id" element={<AdminVendoredit />} />

               



              <Route path="/umy-admin-portal/enquiries" element={<AdminEnquirieslist />} />
              <Route path="/umy-admin-portal/subscribers" element = {<AdminSubscriberslist />} />

 
               <Route path="/umy-admin-portal/faq" element={<AdminFaqlist />} />
              <Route path="/umy-admin-portal/faq/add" element={<AdminFaqadd />} />
             <Route path="/umy-admin-portal/faq/edit/:id" element={<AdminFaqedit />} />

                  <Route path="/umy-admin-portal/faqgroup" element={<AdminFaqgrouplist />} />
              <Route path="/umy-admin-portal/faqgroup/add" element={<AdminFaqgroupadd />} />
             <Route path="/umy-admin-portal/faqgroup/edit/:id" element={<AdminFaqgroupedit />} />

               
              < Route path="/umy-admin-portal/sliders" element={<AdminSliderlist />} />
              <Route path="/umy-admin-portal/sliders/add" element={<AdminSlideradd />} />
             <Route path="/umy-admin-portal/sliders/edit/:id" element={<AdminSlideredit />} />

           

              < Route path="/umy-admin-portal/blog" element={<AdminBloglist />} />
              <Route path="/umy-admin-portal/blog/add" element={<AdminBlogadd />} />
             <Route path="/umy-admin-portal/blog/edit/:id" element={<AdminBlogedit />} />



              < Route path="/umy-admin-portal/blogcategory" element={<AdminBlogcategorylist />} />
              <Route path="/umy-admin-portal/blogcategory/add" element={<AdminBlogcategoryadd />} />
             <Route path="/umy-admin-portal/blogcategory/edit/:id" element={<AdminBlogcategoryedit />} />


     
              < Route path="/umy-admin-portal/languages" element={<AdminLanguagelist />} />
              <Route path="/umy-admin-portal/languages/add" element={<AdminLanguageadd />} />
             <Route path="/umy-admin-portal/languages/edit/:id" element={<AdminLanguageadd />} />
                           < Route path="/umy-admin-portal/languages/addwords/:id" element={<AdminLanguageAddwords/>} />


                                         < Route path="/umy-admin-portal/currencies" element={<AdminCurrencylist />} />
              <Route path="/umy-admin-portal/currencies/add" element={<AdminCurrencyadd />} />
             <Route path="/umy-admin-portal/currencies/edit/:id" element={<AdminCurrencyedit />} />

              <Route path="/umy-admin-portal/plans" element={<AdminPlanlist />} />
              <Route path="/umy-admin-portal/plans/add" element={<AdminPlanadd />} />
             <Route path="/umy-admin-portal/plans/edit/:id" element={<AdminPlanedit />} />




  <Route path="/umy-admin-portal/categories" element={<AdminCategorylist />} />
              <Route path="/umy-admin-portal/categories/add" element={<AdminCategoryadd />} />
             <Route path="/umy-admin-portal/categories/edit/:id" element={<AdminCategoryedit />} />




      <Route path="/umy-admin-portal/subcategories" element={<AdminSubcategorylist />} />
      <Route path="/umy-admin-portal/subcategories/add" element={<AdminSubcategoryadd />} />
      <Route path="/umy-admin-portal/subcategories/edit/:id" element={<AdminSubcategoryedit />} />



      <Route path="/umy-admin-portal/services" element={<AdminServicelist />} />
      <Route path="/umy-admin-portal/services/add" element={<AdminServiceadd />} />
      <Route path="/umy-admin-portal/services/edit/:id" element={<AdminServiceedit />} />



      <Route path="/umy-admin-portal/bookings" element={<AdminBookingslist />} />


       <Route path="/umy-admin-portal/notifications" element={<AdminNotificationslist />} />
       <Route path="/umy-admin-portal/notifications/add" element={<AdminNotificationsadd />} />





        </Routes>
       
    </div>
    );
}

export default App;
