import React, { useEffect, useState } from "react";

import { TextSpaceonlyValidation, Notifyalert, requiredErrormessage, Url, CommonNotify, UrlSplitter, checkEmptyUndefined, redirectPage, validEmailRegex, checkpermissionredirect } from './../Api.js';


import Header from "../includes/Header.js";
import Sidebar from "../includes/Sidebar.js";
import Navbar from "../includes/Navbar";
import Pagetitle from "../includes/Pagetitle";

import Footer from "../includes/Footer";

import {
   Allowvendors_view,
   Allowvendors_add,
   Allowvendors_edit,
   Allowvendors_delete
} from "../../admin/Permissions.js"



const Profile = () => {


   const [userid] = useState(UrlSplitter(4));

   const [fullname, setFullname] = useState("");
   const [username, setUsername] = useState("");
   const [email, setEmail] = useState("");
   const [phonenumber, setPhonenumber] = useState("");

   const [password, setPassword] = useState("");
   const [confirmpassword, setConfirmPassword] = useState("");
   const [status, setStatus] = useState(1);
   const [profilepicture, setProfilePicture] = useState(null);




   const [address, setAddress] = useState('');
   const [area, setArea] = useState('');
   const [city, setCity] = useState('');
   const [state, setState] = useState('');
   const [country, setCountry] = useState('');
   const [postcode, setPostcode] = useState('');



   const [addressreqerror, setaddressreqerror] = useState('');
   const [areareqerror, setareareqerror] = useState('');
   const [cityreqerror, setcityreqerror] = useState('');
   const [statereqerror, setstatereqerror] = useState('');
   const [countryreqerror, setcountryreqerror] = useState('');
   const [postcodereqerror, setpostcodereqerror] = useState('');


   const [accountholdername, setAccountholdername] = useState('');
   const [bankname, setBankname] = useState('');
   const [taxnumber, setTaxnumber] = useState('');
   const [bankswiftcode, setBankswiftcode] = useState('');
   const [bankaccountnumber, setBankaccountnumber] = useState('');


   const [accountholdernamereqerror, setAccountholdernamereqerror] = useState('');
   const [banknamereqerror, setBanknamereqerror] = useState('');
   const [taxnumberreqerror, setTaxnumberreqerror] = useState('');
   const [bankswiftcodereqerror, setBankswiftcodereqerror] = useState('');
   const [bankaccountnumberreqerror, setBankaccountnumberreqerror] = useState('');



   const [addressproof, setAddressproof] = useState(null);
   const [idproof, setIdproof] = useState(null);
   const [companyproof, setCompanyproof] = useState(null);
   const [kycapproved, setKycapproved] = useState("");


   const [addressproofreqerror, setAddressproofreqerror] = useState("");
   const [idproofreqerror, setIdproofreqerror] = useState("");
   const [companyproofreqerror, setCompanyproofreqerror] = useState("");
   const [kycapprovedreqerror, setKycapprovedreqerror] = useState("");




   const [fullnamereqerror, setfullnamereqerror] = useState("");
   const [emailreqerror, setemailreqerror] = useState("");
   const [phonenumberreqerror, setphonenumberreqerror] = useState("");


   const [usernamereqerror, setusernamereqerror] = useState("");
   const [passwordreqerror, setpasswordreqerror] = useState("");
   const [confirmpasswordreqerror, setconfirmpasswordreqerror] = useState("");

   const [emailexistserror, setemailexistserror] = useState("");
   const [usernameexistserror, setusernameexistserror] = useState("");

   const [emailexistsvalidation, setemailexistsvalidation] = useState("0");
   const [usernameexistsvalidation, setusernameexistsvalidation] = useState("0");


   const [emailvaliderror, setemailvaliderror] = useState("");


   const [activeTab, setActiveTab] = useState('basic');
   const [isFormComplete, setIsFormComplete] = useState(false);






   const Getuser = async (e) => {
      try {
         var postdata = { _id: userid };

         const response = await fetch(Url + "getvendors", {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
            },
            body: JSON.stringify({ postdata }),
         })
            .then((response) => response.json())
            .then((data) => {
               if (data.success) {
                  var userdata = data.data;

                  // Basic Fields
                  setFullname(userdata.fullname);
                  setUsername(userdata.username);
                  setEmail(userdata.email);
                  setPhonenumber(userdata.phonenumber);
                  setStatus(userdata.status);

                  // Address Fields
                  setAddress(userdata.address);
                  setArea(userdata.area);
                  setCity(userdata.city);
                  setState(userdata.state);
                  setCountry(userdata.country);
                  setPostcode(userdata.postcode);

                  // Payment Fields
                  setAccountholdername(userdata.accountholdername);
                  setBankname(userdata.bankname);
                  setTaxnumber(userdata.taxnumber);
                  setBankswiftcode(userdata.bankswiftcode);
                  setBankaccountnumber(userdata.bankaccountnumber);

                  // KYC Fields
                  setAddressproof(userdata.addressproof);
                  setIdproof(userdata.idproof);
                  setCompanyproof(userdata.companyproof);
                  setKycapproved(userdata.kycapproved);
               } else {
                  // 
               }
            });
      } catch (error) {
         // 
      }
   };



   const handleVendorInput = (e) => {
      const { name, value } = e.target;

      switch (name) {
         case 'fullname':
            if (TextSpaceonlyValidation(value)) {
               setFullname(value);
               setfullnamereqerror('');
            } else {
               setfullnamereqerror('Invalid name');
            }
            break;

         case 'email':
            setEmail(value);
            setemailreqerror('');
            break;

         case 'username':
            setUsername(value);
            setusernamereqerror('');
            break;

         case 'password':
            setPassword(value);
            setpasswordreqerror('');
            break;

         case 'confirmpassword':
            setConfirmPassword(value);
            setconfirmpasswordreqerror('');
            break;

         case 'status':
            setStatus(value);
            break;

         case 'profilepicture':
            setProfilePicture(e.target.files[0]);
            break;

         // Address fields
         case 'address':
            setAddress(value);
            setaddressreqerror('');
            break;

         case 'area':
            setArea(value);
            setareareqerror('');
            break;

         case 'city':
            setCity(value);
            setcityreqerror('');
            break;

         case 'state':
            setState(value);
            setstatereqerror('');
            break;

         case 'country':
            setCountry(value);
            setcountryreqerror('');
            break;

         case 'postcode':
            setPostcode(value);
            setpostcodereqerror('');
            break;

         // Payment fields
         case 'accountholdername':
            setAccountholdername(value);
            setAccountholdernamereqerror('');
            break;

         case 'bankname':
            setBankname(value);
            setBanknamereqerror('');
            break;

         case 'taxnumber':
            setTaxnumber(value);
            setTaxnumberreqerror('');
            break;

         case 'bankswiftcode':
            setBankswiftcode(value);
            setBankswiftcodereqerror('');
            break;

         case 'bankaccountnumber':
            setBankaccountnumber(value);
            setBankaccountnumberreqerror('');
            break;

         // KYC fields
         case 'addressproof':
            setAddressproof(value);
            setAddressproofreqerror('');
            break;

         case 'idproof':
            setIdproof(value);
            setIdproofreqerror('');
            break;

         case 'companyproof':
            setCompanyproof(value);
            setCompanyproofreqerror('');
            break;

         case 'kycapproved':
            setKycapproved(value);
            setKycapprovedreqerror('');
            break;

         default:
            break;
      }

      // Check form completion after every change
      checkFormCompletion();
   };


   const Updateuser = async (e) => {
      e.preventDefault();
      console.log("Form Submitted");

      setfullnamereqerror('');
      setusernamereqerror('');
      setemailreqerror('');
      setpasswordreqerror('');
      setconfirmpasswordreqerror('');
      setaddressreqerror('');
      setareareqerror('');
      setcityreqerror('');
      setstatereqerror('');
      setcountryreqerror('');
      setpostcodereqerror('');

      setAccountholdernamereqerror('');
      setBanknamereqerror('');
      setTaxnumberreqerror('');
      setBankswiftcodereqerror('');
      setBankaccountnumberreqerror('');

      setAddressproofreqerror('');
      setIdproofreqerror('');
      setCompanyproofreqerror('');
      setKycapprovedreqerror('');

      var validationerror = 0;

      // Basic validation
      if (checkEmptyUndefined(fullname) == false) {
         setfullnamereqerror(requiredErrormessage);
         validationerror = 1;
      }
      if (checkEmptyUndefined(username) == false) {
         setusernamereqerror(requiredErrormessage);
         validationerror = 1;
      }
      if (checkEmptyUndefined(email) == false) {
         setemailreqerror(requiredErrormessage);
         validationerror = 1;
      }
      if (checkEmptyUndefined(email) == true && validEmailRegex.test(email) == false) {
         setemailreqerror("Invalid Email");
         validationerror = 1;
      }

      // Password validation
      if (typeof userid == 'undefined' || userid == '') {
         if (checkEmptyUndefined(password) == false) {
            setpasswordreqerror(requiredErrormessage);
            validationerror = 1;
         }
         if (checkEmptyUndefined(confirmpassword) == false) {
            setconfirmpasswordreqerror(requiredErrormessage);
            validationerror = 1;
         }
         if (password !== confirmpassword) {
            setconfirmpasswordreqerror("Password and Confirm Password Should match");
            validationerror = 1;
         }
      } else {
         if (password !== '') {
            if (password !== confirmpassword) {
               setconfirmpasswordreqerror("Password and Confirm Password Should match");
               validationerror = 1;
            }
         }
      }

      // Address validation
      if (checkEmptyUndefined(address) == false) {
         setaddressreqerror(requiredErrormessage);
         validationerror = 1;
      }
      if (checkEmptyUndefined(area) == false) {
         setareareqerror(requiredErrormessage);
         validationerror = 1;
      }
      if (checkEmptyUndefined(city) == false) {
         setcityreqerror(requiredErrormessage);
         validationerror = 1;
      }
      if (checkEmptyUndefined(state) == false) {
         setstatereqerror(requiredErrormessage);
         validationerror = 1;
      }
      if (checkEmptyUndefined(country) == false) {
         setcountryreqerror(requiredErrormessage);
         validationerror = 1;
      }
      if (checkEmptyUndefined(postcode) == false) {
         setpostcodereqerror(requiredErrormessage);
         validationerror = 1;
      }

      // Payment validation
      if (checkEmptyUndefined(accountholdername) == false) {
         setAccountholdernamereqerror(requiredErrormessage);
         validationerror = 1;
      }
      if (checkEmptyUndefined(bankname) == false) {
         setBanknamereqerror(requiredErrormessage);
         validationerror = 1;
      }
      if (checkEmptyUndefined(taxnumber) == false) {
         setTaxnumberreqerror(requiredErrormessage);
         validationerror = 1;
      }
      if (checkEmptyUndefined(bankswiftcode) == false) {
         setBankswiftcodereqerror(requiredErrormessage);
         validationerror = 1;
      }
      if (checkEmptyUndefined(bankaccountnumber) == false) {
         setBankaccountnumberreqerror(requiredErrormessage);
         validationerror = 1;
      }

      // KYC validation
      if (checkEmptyUndefined(addressproof) == false) {
         setAddressproofreqerror(requiredErrormessage);
         validationerror = 1;
      }
      if (checkEmptyUndefined(idproof) == false) {
         setIdproofreqerror(requiredErrormessage);
         validationerror = 1;
      }
      if (checkEmptyUndefined(companyproof) == false) {
         setCompanyproofreqerror(requiredErrormessage);
         validationerror = 1;
      }
      if (checkEmptyUndefined(kycapproved) == false) {
         setKycapprovedreqerror(requiredErrormessage);
         validationerror = 1;
      }

      if (validationerror == 0) {
         var statusupdate = (typeof status == 'undefined' || status == '' || status == '1') ? '1' : '0';

         var postdata = {
            _id: userid,
            fullname: fullname,
            username: username,
            email: email,
            status: statusupdate,
            password: password,
            accountholdername: accountholdername,
            bankname: bankname,
            taxnumber: taxnumber,
            bankswiftcode: bankswiftcode,
            bankaccountnumber: bankaccountnumber,
            address: address,
            area: area,
            city: city,
            state: state,
            country: country,
            postcode: postcode,
            addressproof: addressproof,
            idproof: idproof,
            companyproof: companyproof,
            kycapproved: kycapproved,
         };

         // API request
         const response = await fetch(Url + "updatevendor", {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
            },
            body: JSON.stringify({ postdata }),
         }).then((response) => response.json())
            .then((data) => {
               if (data.success) {
                  Notifyalert('success', data.message);

                  setFullname('');
                  setUsername('');
                  setEmail('');
                  setPassword('');
                  setConfirmPassword('');
                  setAddress('');
                  setArea('');
                  setCity('');
                  setState('');
                  setCountry('');
                  setPostcode('');
                  setAccountholdername('');
                  setBankname('');
                  setTaxnumber('');
                  setBankswiftcode('');
                  setBankaccountnumber('');
                  setAddressproof('');
                  setIdproof('');
                  setCompanyproof('');
                  setKycapproved('');


               } else {
                  Notifyalert('error', data.message);
               }
            })
            .catch((error) => {
               Notifyalert('error', 'An error occurred, please try again.');
            });
      }
   };

   const checkFormCompletion = () => {

      const isComplete = [
         fullname, username, email, phonenumber, password, confirmpassword,
         address, area, city, state, country, postcode,
         accountholdername, bankname, taxnumber, bankswiftcode, bankaccountnumber,
         addressproof, idproof, companyproof, kycapproved
      ].every((value) => value !== '');

      setIsFormComplete(isComplete);
   };

   const handleNextTab = () => {
      if (activeTab === 'basic') {
         setActiveTab('address');
      } else if (activeTab === 'address') {
         setActiveTab('payment');
      } else if (activeTab === 'payment') {
         setActiveTab('kyc');
      } else if (activeTab === 'kyc') {

         Updateuser();
      }
   };


   const handlePreviousTab = () => {
      if (activeTab === 'address') {
         setActiveTab('basic');
      } else if (activeTab === 'payment') {
         setActiveTab('address');
      } else if (activeTab === 'kyc') {
         setActiveTab('payment');
      }
   };

   const handleFormChange = (event) => {

      setIsFormComplete(true);
   };


   useEffect(() => {
      Getuser(userid);

      if (typeof userid != 'undefined' && userid != '') {
         checkpermissionredirect(Allowvendors_edit)
      } else {
         checkpermissionredirect(Allowvendors_add)

      }
   }, []);



   return (

      <div id="app">
         <Header title={typeof id !== 'undefined' ? "Edit Vendor" : "Add Vendor"} />

         <Sidebar activePage="Vendors" activeChildPage="Add" />
         <div id="main">
            <Navbar />

            <div className="main-content container-fluid">

               <Pagetitle activePage={typeof id !== 'undefined' ? "Edit Vendor" : "Add Vendor"} />

               <div className="row match-height">
                  <div className="col-md-9 col-12 mx-auto">
                     <div className="card">
                        <div className="card-header">

                           <ul className="nav nav-tabs" id="myTab" role="tablist">
                              <li className="nav-item" role="presentation">
                                 <a className="nav-link active card-title" id="basic-tab" data-bs-toggle="tab" href="#basic" role="tab" aria-controls="basic" aria-selected="true" > Basic </a>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <a className="nav-link card-title" id="address-tab" data-bs-toggle="tab" href="#address" role="tab" aria-controls="address" aria-selected="false"> Address </a>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <a className="nav-link card-title" id="payment-tab" data-bs-toggle="tab" href="#payment" role="tab" aria-controls="payment" aria-selected="false"> Payment Details </a>
                              </li>
                              {/* <li className="nav-item" role="presentation">
                                 <a className="nav-link card-title" id="categories-tab" data-bs-toggle="tab" href="#categories" role="tab" aria-controls="categories" aria-selected="false" > Categories / Services</a>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <a className="nav-link card-title" id="cities-tab" data-bs-toggle="tab" href="#cities" role="tab" aria-controls="cities" aria-selected="false"  >  Cities
                                 </a>
                              </li> */}
                              <li className="nav-item" role="presentation">
                                 <a className="nav-link card-title" id="kyc-tab" data-bs-toggle="tab" href="#kyc" role="tab" aria-controls="kyc" aria-selected="false" >
                                    KYC
                                 </a>
                              </li>
                           </ul>
                        </div>


                        <div className="card-content">
                           <div className="card-body">
                              <form className="form form-horizontal" autocomplete="off">
                                 <div className="form-body">

                                    <div className="tab-content" id="myTabContent">

                                       <div className={`tab-pane fade ${activeTab === 'basic' ? 'show active' : ''}`} id="basic" role="tabpanel" aria-labelledby="basic-tab" >
                                          <div className="row">
                                             {/* Basic Fields  */}

                                             <div className="col-md-4">
                                                <label>Name <span className="field-required">*</span></label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" value={fullname} type="text" name="fullname" placeholder="Name"
                                                   onChange={(e) => handleVendorInput(e)}
                                                   required />
                                                <p className="req-error-message">{fullnamereqerror}</p>

                                             </div>
                                             <div className="col-md-4">
                                                <label>Username <span className="field-required">*</span></label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" type="text" name="username" placeholder="Username" value={username}
                                                   onChange={(e) => handleVendorInput(e)}
                                                   required />
                                                <p className="req-error-message">{usernamereqerror}</p>

                                             </div>

                                             <div className="col-md-4">
                                                <label>Email <span className="field-required">*</span></label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" type="text" name="email" placeholder="Email" value={email} onChange={(e) => handleVendorInput(e)} required />
                                                <p className="req-error-message">{emailreqerror}</p>

                                             </div>

                                             <div className="col-md-4">
                                                <label>Phone Number <span className="field-required">*</span></label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" type="text" name="phonenumber" placeholder="Phone Number" value={phonenumber} onChange={(e) => handleVendorInput(e)} required />
                                                <p className="req-error-message">{phonenumberreqerror}</p>
                                             </div>

                                             <div className="col-md-4">
                                                <label>Password</label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" type="password" name="password" placeholder="Password" value={password} onChange={(e) => handleVendorInput(e)} required />
                                                <p className="req-error-message">{passwordreqerror}</p>
                                             </div>

                                             <div className="col-md-4">
                                                <label>Confirm Password</label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" type="password" name="confirmpassword" placeholder="Confirm Password" value={confirmpassword} onChange={(e) => handleVendorInput(e)} required />
                                                <p className="req-error-message">{confirmpasswordreqerror}</p>
                                             </div>

                                             <div className="col-md-4">
                                                <label>Profile Image <span className="field-required">*</span></label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" type="file" name="profilepicture" accept="image/*" required />

                                             </div>
                                          </div>
                                       </div>



                                       {/* Address Tab Content */}
                                       <div className={`tab-pane fade ${activeTab === 'address' ? 'show active' : ''}`} id="address" role="tabpanel" aria-labelledby="address-tab"  >
                                          <div className="row">
                                             <div className="col-md-4">
                                                <label>Address <span className="field-required">*</span></label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" type="text" name="address" placeholder="Address" value={address} onChange={(e) => handleVendorInput(e)} required />
                                                <p className="req-error-message">{addressreqerror}</p>
                                             </div>

                                             <div className="col-md-4">
                                                <label>Area <span className="field-required">*</span></label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" type="text" name="area" placeholder="Area" value={area} onChange={(e) => handleVendorInput(e)} required />
                                                <p className="req-error-message">{areareqerror}</p>
                                             </div>

                                             <div className="col-md-4">
                                                <label>City <span className="field-required">*</span></label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" type="text" name="city" placeholder="City" value={city} onChange={(e) => handleVendorInput(e)} required />
                                                <p className="req-error-message">{cityreqerror}</p>
                                             </div>

                                             <div className="col-md-4">
                                                <label>State <span className="field-required">*</span></label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" type="text" name="state" placeholder="State" value={state} onChange={(e) => handleVendorInput(e)} required />
                                                <p className="req-error-message">{statereqerror}</p>
                                             </div>

                                             <div className="col-md-4">
                                                <label>Country <span className="field-required">*</span></label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" type="text" name="country" placeholder="Country" value={country} onChange={(e) => handleVendorInput(e)} required />
                                                <p className="req-error-message">{countryreqerror}</p>
                                             </div>

                                             <div className="col-md-4">
                                                <label>Postcode <span className="field-required">*</span></label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" type="text" name="postcode" placeholder="Postcode" value={postcode} onChange={(e) => handleVendorInput(e)} required />
                                                <p className="req-error-message">{postcodereqerror}</p>
                                             </div>
                                          </div>
                                       </div>

                                       {/* payment */}

                                       <div className={`tab-pane fade ${activeTab === 'payment' ? 'show active' : ''}`} id="payment" role="tabpanel" aria-labelledby="payment-tab">
                                          <div className="row">
                                             <div className="col-md-4">
                                                <label>Account Holder Name <span className="field-required">*</span></label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" type="text" name="accountholdername" placeholder="Account Holder Name" value={accountholdername} onChange={(e) => handleVendorInput(e)} required />
                                                <p className="req-error-message">{accountholdernamereqerror}</p>
                                             </div>

                                             <div className="col-md-4">
                                                <label>Bank Name <span className="field-required">*</span></label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" type="text" name="bankname" placeholder="Bank Name" value={bankname} onChange={(e) => handleVendorInput(e)} required />
                                                <p className="req-error-message">{banknamereqerror}</p>
                                             </div>

                                             <div className="col-md-4">
                                                <label>Tax Number <span className="field-required">*</span></label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" type="text" name="taxnumber" placeholder="Tax Number" value={taxnumber} onChange={(e) => handleVendorInput(e)} required />
                                                <p className="req-error-message">{taxnumberreqerror}</p>
                                             </div>

                                             <div className="col-md-4">
                                                <label>Bank Swift Code <span className="field-required">*</span></label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" type="text" name="bankswiftcode" placeholder="Bank Swift Code" value={bankswiftcode} onChange={(e) => handleVendorInput(e)} required />
                                                <p className="req-error-message">{bankswiftcodereqerror}</p>
                                             </div>

                                             <div className="col-md-4">
                                                <label>Bank Account Number <span className="field-required">*</span></label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" type="text" name="bankaccountnumber" placeholder="Bank Account Number" value={bankaccountnumber} onChange={(e) => handleVendorInput(e)} required />
                                                <p className="req-error-message">{bankaccountnumberreqerror}</p>
                                             </div>
                                          </div>
                                       </div>

                                       {/* kyc */}

                                       <div className={`tab-pane fade ${activeTab === 'kyc' ? 'show active' : ''}`} id="kyc" role="tabpanel" aria-labelledby="kyc-tab" >
                                          <div className="row">

                                             <div className="col-md-4">
                                                <label>Address Proof <span className="field-required">*</span></label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" type="file" name="addressproof" onChange={(e) => handleVendorInput(e)} required />
                                                <p className="req-error-message">{addressproofreqerror}</p>
                                             </div>

                                             <div className="col-md-4">
                                                <label>ID Proof <span className="field-required">*</span></label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" type="file" name="idproof" onChange={(e) => handleVendorInput(e)} required />
                                                <p className="req-error-message">{idproofreqerror}</p>
                                             </div>

                                             <div className="col-md-4">
                                                <label>Company Proof <span className="field-required">*</span></label>
                                             </div>
                                             <div className="col-md-8 form-group">
                                                <input className="form-control" type="file" name="companyproof" onChange={(e) => handleVendorInput(e)} required />
                                                <p className="req-error-message">{companyproofreqerror}</p>
                                             </div>

                                          </div>
                                       </div>

                                       {/* <div className="col-sm-12 d-flex justify-content-end">
                                          <a className="btn btn-primary me-1 mb-1" onClick={Updateuser}>
                                             Submit
                                          </a>
                                       </div> */}

                                       {/* <div className="col-sm-12 d-flex justify-content-end">
                                          {activeTab !== 'kyc' ? (
                                             <button className="btn btn-primary me-1 mb-1" onClick={handleNextTab}>
                                                Next
                                             </button>
                                          ) : (
                                             <button className="btn btn-primary me-1 mb-1" onClick={Updateuser}>
                                                Submit
                                             </button>
                                          )}
                                       </div> */}

                                       <div className="col-sm-12 d-flex justify-content-end">
                                          {activeTab !== 'basic' && (
                                             <button className="btn btn-secondary me-1 mb-1" onClick={handlePreviousTab}>
                                                Previous
                                             </button>
                                          )}

                                          {activeTab !== 'kyc' ? (
                                             <button className="btn btn-primary me-1 mb-1" onClick={handleNextTab}>
                                                Next
                                             </button>
                                          ) : (
                                             <button className="btn btn-primary me-1 mb-1" onClick={Updateuser}>
                                                Submit
                                             </button>
                                          )}
                                       </div>

                                    </div>


                                 </div>
                              </form>
                           </div>

                        </div>


                     </div>
                  </div>
               </div>
               <Footer />
            </div>


         </div>

      </div>



   );
};
export default Profile;





