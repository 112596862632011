import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import md5 from "md5"; 
import { SiteLogo, baseUrl, Redirectdashboard, validEmailRegex, CommonNotify, checkEmptyUndefined } from './../Api.js';
import Header from "../includes/Header";

const Login = () => {
    Redirectdashboard();  

    const navigate = useNavigate(); 
    const [notification, setnotification] = useState("");  
    const [notificationtype, setnotificationtype] = useState("");  
    const [email, setEmail] = useState(""); 
    const [password, setPassword] = useState("");  
    const [emailError, setEmailerror] = useState(false); 
    const [emailvalid, setEmailvalid] = useState("");  
    const [passwordvalid, setPasswordvalid] = useState("");  
    const [formValid, setformValid] = useState(true);  
    const [validationSubmit, setvalidationSubmit] = useState(false);  
    const [submitLoading, setsubmitLoading] = useState(false);  


    const handleUserInput = (e) => {
        setnotification("");  
        const { name, value } = e.target;  

        switch (name) {
            case 'email':
                setEmail(value);
                setEmailvalid(true);
                validEmailRegex.test(value) ? setEmailerror(false) : setEmailerror(true);
                break;
            case 'password':
                setPassword(value);
                setPasswordvalid(true);
                break;
            default:
                break;
        }
    };

    
    const signIn = async (e) => {
        e.preventDefault();  

        setsubmitLoading(true); 
        if (checkEmptyUndefined(email) === false || checkEmptyUndefined(password) === false) {
            setnotificationtype('error');
            setnotification('* Fields Required');
            setsubmitLoading(false);
            return false;  
        }

        setvalidationSubmit(true); 

        const hashedPassword = md5(password);  
        console.log("Hashed Password (Frontend):", hashedPassword);  

        const postdata = {
            email: email,
            password: hashedPassword,  
        };

        try {
            const response = await fetch("http://localhost:5090/vendor-portal-login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(postdata),
            });

            const data = await response.json();  
            console.log("Response from backend:", data); 

            if (data.success) {
                const userdata = data.data;  
                localStorage.setItem('vuserId', userdata._id);
                localStorage.setItem('vuserEmail', userdata.email);
                localStorage.setItem('vuserName', userdata.username);
                localStorage.setItem('vendorprofileimage', userdata.profilepicture);

        
                navigate("/vendor/dashboard");
            } else {
                setnotificationtype('error');
                setnotification(data.message);
            }
            setsubmitLoading(false);
        } catch (error) {
            setsubmitLoading(false);
            console.error("Error during login:", error);
            setnotificationtype('error');
            setnotification('An error occurred while logging in.');
        }
    };

    return (
        <div id="auth">
            <div className="container">
                <div className="row">
                    <div className="col-md-5 col-sm-12 mx-auto">
                        <div className="card pt-4">
                            <div className="card-body">
                                <div className="text-center mb-5">
                                    <img src={SiteLogo} height="48" className="mb-4" alt="Site Logo" />
                                    <h3>Sign In</h3>
                                    <p>Please sign in to continue.</p>
                                </div>
                                <form autoComplete="off" onSubmit={signIn}>
                                    <div className="form-group position-relative has-icon-right">
                                        <label htmlFor="username">Email Address / Phone Number <span className="field-required">*</span></label>
                                        <div className="position-relative">
                                            <input
                                                type="email"
                                                name="email"
                                                className={`form-control ${(!emailvalid && validationSubmit) ? 'is-invalid' : ''}`}
                                                placeholder="Username@gmail.com"
                                                onChange={handleUserInput}
                                                required
                                            />
                                            <div className="form-control-icon">
                                                <i data-feather="user"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group position-relative has-icon-right">
                                        <div className="clearfix">
                                            <label htmlFor="password">Password<span className="field-required">*</span></label>
                                            <a href={baseUrl + 'forgot-password'} className="float-end">
                                                <small>Forgot password?</small>
                                            </a>
                                        </div>
                                        <div className="position-relative">
                                            <input type="password" name="password" className={`form-control ${(!passwordvalid && validationSubmit) ? 'is-invalid' : ''}`} placeholder="············" onChange={handleUserInput} required  />
                                            <div className="form-control-icon">
                                                <i data-feather="lock"></i>
                                            </div>
                                        </div>
                                    </div>

                            
                                    {(!emailError && formValid && !submitLoading) ? (
                                        <div className="clearfix">
                                            <button className="btn btn-primary float-end" type="submit">
                                                Submit
                                            </button>
                                        </div>
                                    ) : null}

                                
                                    {notification && CommonNotify(notification, notificationtype)}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
