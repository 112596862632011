import React, {useEffect, useState } from "react";
 
 import {TextSpaceonlyValidation,Notifyalert,requiredErrormessage,Url, CommonNotify,UrlSplitter,checkEmptyUndefined,redirectPage,    validEmailRegex ,checkpermissionredirect} from './../../Api.js';
  import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";
 
import {    
    Allownotifications_view ,
    Allownotifications_add ,
    Allownotifications_edit ,
    Allownotifications_delete 
} from './../../Permissions.js';

 

const Adduser = () => {

  
const[userid] = useState(UrlSplitter(4));

const[title, setTitle] = useState(""); 
const[content, setContent] = useState(""); 
const[usertype, setUsertype] = useState(""); 

const [image, setImage] = useState({preview:''})

const [titlereqerror, settitlereqerror] = useState()

const [contentreqerror, setcontentreqerror] = useState()
const [imagereqerror, setimagereqerror] = useState()
const [usertypereqerror, setusertypereqerror] = useState()




const [showcustomers, setShowcustomers] = useState(0)
const [showvendors, setShowvendors] = useState(0)

    

const [customerid, setCustomerid] = useState(0)
const [vendorid, setVendorid] = useState(0)



 const [customerslist, setcustomerslist] = useState([]);
 const [vendorslist, setvendorslist] = useState([]);


const handleNotificationInput = (e) => {


     var name = e.target.name;
    var value = e.target.value;
     switch (name) {
        case 'title':
              setTitle(value);
              settitlereqerror('')
               break;      

         case 'customer':
              setCustomerid(value);   
               break;      
              
        case 'vendor':
              setVendorid(value); 
               break;                
        

        case 'usertype':
              setUsertype(value);    
              setusertypereqerror('')   
               break;      
             
         
        default:
            break;      
    }
 }
 


  const Addnotification = async (e) => {

    settitlereqerror('');
    setcontentreqerror('');
 
 
    var validationerror = 0;
 
    if(checkEmptyUndefined(title) == false){
        settitlereqerror(requiredErrormessage)
        validationerror=1;
    }
    if(checkEmptyUndefined(content) == false){
        setcontentreqerror(requiredErrormessage)
        validationerror=1;
    } 


    if(checkEmptyUndefined(usertype) == false){
        setusertypereqerror(requiredErrormessage)
        validationerror=1;
    }   
     
 
     if(validationerror == 0){ 


        let formData = new FormData()
        formData.append('title', title)
        formData.append('content', content)
         formData.append('image', image.data)
        formData.append('usertype', usertype)
         formData.append('customerid', customerid)
         formData.append('vendorid', vendorid)

 


            const response = await fetch(Url+"addnotification", {
        method: "POST",
       
        body: formData
      }).then((response) => response.json())

 
         .then((data) => { 
          if (data.success) {

                                   Notifyalert('success',data.message)

       
          } else{
                 Notifyalert('error',data.message)
          }

           
       })

     }
  
  };





 const changeUsertypeInput = (e) => {

 
    if(e.target.value == 'vendor'){

        setShowcustomers(0)
        setShowvendors(1)

    }

    if(e.target.value == 'customer'){

        setShowcustomers(1)
        setShowvendors(0)

    }
 
        }

    const handlePictureChange = (e) => {

             const img = {
                preview: URL.createObjectURL(e.target.files[0]),
                data: e.target.files[0],
            }
            var name = e.target.files[0].name;
            var lastDot = name.lastIndexOf('.');
            var fileName = name.substring(0, lastDot);
            var ext = name.substring(lastDot + 1);
            setImage({
                preview: ''
            })
            if (ext == 'jpg' || ext == 'png' || ext == 'webp' || ext == 'jpeg') {
                setImage(img)
            } else {
                setimagereqerror("Invalid Image Format")
             }
        }



const GetCustomers = async (value ) => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)

 
      var postdata={ all :1};

        const response = await fetch(Url+"getcustomers", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
        setcustomerslist(data.data );
       
         
          }  
           
       })


 
  };


const GetVendors = async (value ) => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)

 
      var postdata={ all : 1};

        const response = await fetch(Url+"getvendors", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
        setvendorslist(data.data );
       
         
          }  
           
       })


 
  };
    
useEffect(() => {
 




GetCustomers()
GetVendors()
 
    checkpermissionredirect(Allownotifications_add)

 

  
  },[] ); 

   return (

       <div id="app">
            <Header title={typeof id !== 'undefined'?"Edit Notification":"Add Notification"} />

         <Sidebar activePage="Notifications"  activeChildPage="Add" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage={typeof id !== 'undefined'?"Edit Notification":"Add Notification"}/>
                              <div className="row match-height">
            <div className="col-md-6 col-12 mx-auto">
            <div className="card">
                <div className="card-header">
                <h4 className="card-title">{typeof id !== 'undefined'?"Edit Notification":"Add Notification"}</h4>
                </div>
                <div className="card-content">
                <div className="card-body">
                    <form className="form form-horizontal" autocomplete="off">
                    <div className="form-body">
                        <div className="row">
                        <div className="col-md-4">
                            <label>Title <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={title}  type="text" name="title" placeholder="Title"  
                onChange={(e) => handleNotificationInput(e)}
                required/>
                                    <p className="req-error-message">{titlereqerror}</p>

                        </div>
                        <div className="col-md-4">
                            <label>Content <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                                        <ReactQuill value={content}  onChange={setContent} name="content" placeholder="Content" rows="10" className="textarea-contact-input MustContactEnterField mb-25"  />

                    <p className="req-error-message">{contentreqerror}</p>

                        </div>
                         
                           <div className="col-md-4">
                            <label>Image <span className="field-required"></span></label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="file" name="image" placeholder="Link"   
                onChange={(e) => handlePictureChange(e)}
                required/>
                 {(image.preview  != '') ? <img className="img-responsive-amigo"  src ={image.preview}  />  : '' }
                    <p className="req-error-message">{imagereqerror}</p>

                        </div>
                         
                         <div className="col-md-4">
                            <label>User Group<span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <select className="form-control"  name="usertype"  onChange={(e) => changeUsertypeInput(e)}> 
                            
                            <option value="">Choose</option>
                            <option value="customer">Customer</option>
                            <option value="vendor">Vendor</option>


                            </select>


                              <p className="req-error-message">{usertypereqerror}</p>

                           
                        </div>

                        </div>



                         {(showcustomers == 1) ?  

                        <div className="row">
                        <div className="col-md-4">
                            <label>Customers <span className="field-required"></span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <select className="form-control"  name="customer"  onChange={(e) => handleNotificationInput(e)}> 
                            
                            <option value="all">All</option>
                               {   customerslist.map((customersingle) => ( 


                                <option value={customersingle._id}>{customersingle.fullname}</option>

                                   ))

                                  
                               }  


                            </select>


 
                           
                        </div>
                        </div>


                        : ''}

                        {(showvendors == 1) ?  

                        <div className="row">
                        <div className="col-md-4">
                            <label>Vendors <span className="field-required"></span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <select className="form-control"  name="vendor"  onChange={(e) => handleNotificationInput(e)}> 
                            
                            <option value="all">All</option>
                              {   vendorslist.map((vendorsingle) => ( 


                                <option value={vendorsingle._id}>{vendorsingle.fullname}</option>

                                   ))

                                  
                               }  

                            </select>


 
                           
                        </div>
                        </div>


                        : ''}


                         <div className="col-sm-12 d-flex justify-content-end">
                            <a  className="btn btn-primary me-1 mb-1" onClick={Addnotification}>Submit</a>
                         </div>
                           




                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
            </div>


                                <Footer />
            </div>


         </div>

      </div>
                   
   
 
  );
};
export default Adduser;