import React, { useState, useEffect } from 'react';

import { Notifyalert, baseUrl, Url, checkpermissionredirect } from './../../Api.js';
import { Allowenquiries_view } from './../../Permissions.js';

import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";
import Footer from "../../includes/Footer";

function List() {
    const [subscribersList, setSubscribersList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const [pageNumbers, setPageNumbers] = useState([]);
    const [totalSubscribers, setTotalSubscribers] = useState(0);


    const goToNextPage = () => {
        if (currentPage < Math.ceil(totalSubscribers / recordsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };


    const goToPrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const goToPage = (value) => {
        setCurrentPage(value);
    };

    const GetSubscribers = async (value) => {
        value = typeof value !== 'undefined' ? value : currentPage;
        var postdata = { currentpage: value, perpage: recordsPerPage };

        try {
            const response = await fetch(Url + "getsubscribers", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ postdata }),
            });

            const data = await response.json();

            if (data.success) {
                setSubscribersList(data.data);  
                setTotalSubscribers(data.count); 
                setPageNumbers([...Array(Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1));
            } else {
                console.error("Failed to fetch subscribers:", data.message);
            }
        } catch (error) {
            console.error("Error fetching subscribers:", error);
        }
    };

    useEffect(() => {
        GetSubscribers(currentPage);
    }, [currentPage]);

    return (
        <div id="app">
            <Header title="Subscribers List" />
            <Sidebar activePage="Subscribers" />
            <div id="main">
                <Navbar />
                <div className="main-content container-fluid">
                    <Pagetitle activePage="Subscribers" />
                    <section className="section">
                        <div className="card">
                            <div className="card-header">
                                <span>Subscribers</span>
                            </div>
                            <div className="card-body">
                                <table className='table table-striped' id="table1">
                                    <thead>
                                        <tr>
                                            <th>Email</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    {subscribersList.length > 0 ?
                                        <tbody>
                                            {subscribersList.map((subscriber) => (
                                                <tr key={subscriber._id}>
                                                    <td>{subscriber.email}</td>
                                                    <td>{subscriber.createddatetime}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        :
                                        <tbody>
                                            <tr>
                                                <td colSpan="2" align="center">No Records Found</td>
                                            </tr>
                                        </tbody>
                                    }
                                </table>
                            </div>
                        </div>

                        {pageNumbers.length > 1 &&
                            <nav>
                                <ul className='pagination pagination-primary justify-content-center'>
                                    <li className="page-item prev-paging-single">
                                        <a className="page-link" onClick={goToPrevPage} href="#">
                                            Previous
                                        </a>
                                    </li>
                                    {pageNumbers.map(pgNumber => (
                                        <li key={pgNumber}
                                            className={`page-item ${currentPage === pgNumber ? 'active' : ''}`}>
                                            <a onClick={(e) => goToPage(pgNumber)} className='page-link' href="#">
                                                {pgNumber}
                                            </a>
                                        </li>
                                    ))}
                                    <li className="page-item paging-next-single">
                                        <a className="page-link" onClick={goToNextPage} href="#">
                                            Next
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        }
                    </section>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default List;
