import React, { useState, useEffect } from "react";

import {
    Link,
    useNavigate
} from "react-router-dom";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { Lang } from "../Languages.js"

import { Redirectlogin, Siteinfo, frontUrl, SiteFavicon } from '../Frontapi.js';

import Header from "../includes/Header";
import Navbar from "../includes/Navbar";
import Footer from "../includes/Footer";

import waterpurifer from '../asserts/water-purifier-image.png';
import ac_img from '../asserts/ac-image.png';
import home_banner from '../asserts/homepage_image.png';
import add_01 from '../asserts/add-01.png';
import add_02 from '../asserts/add-02.png';
import add_03 from '../asserts/add-03.png';

import './Home.css';

import Ac_image from '../asserts/ac_image-removebg-preview.png'
import googleplayicon from '../asserts/Play Store Icon 2.png';
import applestoreicon from '../asserts/Apple store Icon 2.png';
import faq_img from '../asserts/faq_img.png';
import callus from '../asserts/callus.png';
import whatsapp from '../asserts/whatsapp.png';
import emailimg from '../asserts/emailimg.png';
import followusimg from '../asserts/followus.png';
import socialicons from '../asserts/folowusiconimg.png';
import addressimg from '../asserts/address.png';
import acservice from '../asserts/ac_service.png'
import acservice_two from '../asserts/acservice2.png';

const services = Siteinfo[1]['servicedata'];



const Home = () => {

    const [name, setName] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [contactMessage, setContactMessage] = useState('');


    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');


    const handleInputChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (email.trim() === '') {
            setMessage('Please enter a valid email address.');
            return;
        }

        const postData = {
            postdata: {
                email: email,
            },
        };

        try {

            const response = await fetch('http://localhost:5090/subscribe-user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            });

            const result = await response.json();

            if (response) {
                if (result.success) {
                    setMessage(result.message);
                } else {
                    setMessage(result.message);
                }
            } else {
                setMessage('An error occurred. Please try again later.');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('An error occurred. Please try again later.');
        }
    };



    const handleContactformSubmit = async (event) => {
        event.preventDefault();

        if (name.trim() === '') {
            toast.error('Please enter your name.');
            return;
        }
        if (contactEmail.trim() === '') {
            toast.error('Please enter a valid email address.');
            return;
        }
        if (phone.trim() === '') {
            toast.error('Please enter your phone number.');
            return;
        }
        if (subject.trim() === '') {
            toast.error('Please enter a subject.');
            return;
        }
        if (contactMessage.trim() === '') {
            toast.error('Please enter your message.');
            return;
        }


        const postData = {
            postdata: {
                name: name,
                email: contactEmail,
                mobile: phone,
                subject: subject,
                message: contactMessage,
            }
        };

        try {

            const response = await fetch('http://localhost:5090/create-new-inquiry', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            });

            const result = await response.json();

            if (response.ok) {
                if (result.success) {
                    toast.success(result.message);
                } else {
                    toast.error(result.message);
                }
            } else {
                toast.error('An error occurred. Please try again later.');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred. Please try again later.');
        }
    };


    const navigate = useNavigate();




    useEffect(() => {
        //   checkss()
        //   Redirectlogin();
    }, []);




    return (

        <div id="app">

            <Header />

            <div className="header_line"></div>


            <div className="container">
                <div className="col-md-12 col-sm-12 col-xs-12 banner paddingzero">
                    <div className="col-md-6 col-sm-6 col-xs-12 paddingzero">
                        <h1 className="home_page_heading">Home services at your doorstep</h1>
                        <div className="banner-border"></div>
                        <p className="home_page_subparagraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                        <div className="normal-box">
                            <div className="what-are-you-looking-for">
                                {/* <h5>What are you looking for?</h5> */}
                                <div className="col-md-12 col-sm-12 col-xs-12 box-top paddingzero">
                                    <a className="col-md-6 col-sm-6 col-xs-12 ">
                                        {/* <div className="normal-box-shadow"> */}
                                        {/* <span>AC & Appliance Repair</span> */}
                                        {/* <img src={frontUrl+'assets/images/frontend/ac-image.png'}/> */}
                                        {/* <img src={ac_img} /> */}

                                        {/* </div> */}
                                    </a>
                                    <a className="col-md-6 col-sm-6 col-xs-12">
                                        {/* <div className="normal-box-shadow"> */}
                                        {/* <span>Native Water Purifier</span> */}
                                        {/* <img src={frontUrl+'assets/images/frontend/water-purifier-image.png'}/> */}
                                        {/* <img src={waterpurifer} /> */}

                                        {/* </div> */}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 banner-img">
                        {/* <img src={frontUrl+'assets/images/frontend/home-banner.png'}/> */}
                        <img src={home_banner} />

                    </div>
                </div>
            </div>

            <div className="services-background">
                <div className="container">
                    <div className="col-md-12 col-sm-12 col-xs-12 services-icons">

                        {(services).map((service) => (


                            <a className="col-md-2 col-sm-2 col-xs-12">
                                <img src={service.image} />
                                <p>{service.name}</p>
                            </a>

                        ))}


                    </div>
                </div>
            </div>

            <div className="container">
    <div className="row add-banner">
        {/* First Card */}
        <div className="col-md-4 col-sm-12 col-xs-12 d-flex flex-column flex-md-row bg_color card">
            <div className="card-text">
                <p className="home_page_subpara">Save on electricity bills with power saver AC service</p>
                <p className="home_page_para">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            </div>
            <img src={acservice} alt="image1" className="card-img" />
        </div>

        {/* Second Card */}
        <div className="col-md-4 col-sm-12 col-xs-12 d-flex flex-column flex-md-row bg_color card">
            <div className="card-text">
                <p className="home_page_subpara">Galti reh gayi toh painting free</p>
                <p className="home_page_para">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            </div>
            <img src={acservice_two} alt="image2" className="card-img" />
        </div>

        {/* Third Card */}
        <div className="col-md-4 col-sm-12 col-xs-12 d-flex flex-column flex-md-row bg_color card">
            <div className="card-text">
                <p className="home_page_subpara">Elevate your wedding glow</p>
                <p className="home_page_para">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            </div>
            <img src={acservice} alt="image3" className="card-img" />
        </div>
    </div>
</div>






            {/*  */}
            {/* <div className="contrainer-fluid home_service_bg_img">
                <div className="container ">
                    <div className="row">
                        <div className="col-lg-12">
                            <p className="ac_doorstep">Home services at your doorstep</p>
                        </div>
                        <div className="col-lg-6">
                            <p className="  home_doorsteo_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <img src={googleplayicon} className="store_icon_img" />
                    </div>
                    <div className="col-lg-2">

                        <img src={applestoreicon} className="store_icon_img" />

                    </div>
                </div>
            </div> */}

            {/* FAQ CONTAINER */}

            <div className="container">
                <div className="home_page_faq">
                    <p className="faq_faq">FAQ</p>
                    <p className="faq_text_heading">Frequently Asked Questions </p>
                    <p className="faq_text">Welcome to our FAQ section. Here, you'll find answers to some of the most</p>
                </div>
            </div>



            <div className="container">
                <div className="row">
                    {/* Left Column - Image */}
                    <div className="col-12 col-md-6 d-flex justify-content-center">
                        <img className="faq_image" src={faq_img} alt="Image of a person thinking" />
                    </div>

                    {/* Right Column - Accordion */}
                    <div className="col-12 col-md-6">
                        <div className="accordion" id="accordionExample">
                            {/* Accordion Item 1 */}
                            <div className="accordion-item faq_accordion_head">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button faq_questions" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Accordion Item #1
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body faq_accordion">
                                        It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element.
                                    </div>
                                </div>
                            </div>

                            {/* Accordion Item 2 */}
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed faq_questions" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Accordion Item #2
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body faq_accordion">
                                        This is a hidden item, and will expand when clicked.
                                    </div>
                                </div>
                            </div>

                            {/* Accordion Item 3 */}
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed faq_questions" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Accordion Item #3
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body faq_accordion">
                                        The accordion allows for smooth transitions.
                                    </div>
                                </div>
                            </div>

                            {/* Accordion Item 4 */}
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFour">
                                    <button className="accordion-button collapsed faq_questions" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        Accordion Item #4
                                    </button>
                                </h2>
                                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div className="accordion-body faq_accordion">
                                        You can customize the accordion with CSS.
                                    </div>
                                </div>
                            </div>

                            {/* Accordion Item 5 */}
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFive">
                                    <button className="accordion-button collapsed faq_questions" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        Accordion Item #5
                                    </button>
                                </h2>
                                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                    <div className="accordion-body faq_accordion">
                                        Just about any HTML can go within, though the transition does limit overflow.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>






            {/* Contactus */}

            <div className="container-fluid">
    <div className="container-fluid">
        <div className="row contactus_homepage">

            {/* Left Column */}
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 p-0 left-column">
                <p className="homepage_contactus">Contact Us</p>
                <div className="line"></div>

                <div className="form-row">
                    <div className="form-group">
                        <input className="form-control homepage_contact_input" type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Your Name" aria-label="name" />
                    </div>
                    <div className="form-group">
                        <input className="form-control homepage_contact_input" type="email" value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} placeholder="Your Email" aria-label="email" />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <input className="form-control homepage_contact_input" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone Number" aria-label="phone" />
                    </div>
                    <div className="form-group">
                        <input className="form-control homepage_contact_input" type="text" value={subject} onChange={(e) => setSubject(e.target.value)} placeholder="Subject" aria-label="subject" />
                    </div>
                </div>

                <div className="form-group">
                    <textarea className="form-control homepage_contact_input" value={contactMessage} onChange={(e) => setContactMessage(e.target.value)} placeholder="Your Message" rows={6} aria-label="message"></textarea>
                </div>

                <div className="form-group submit-button-container">
                    <button className="contactus_submit_button" onClick={handleContactformSubmit}>Submit</button>
                </div>
                <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            </div>

            {/* Right Column */}
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 p-0 right-column homepage_contactus_secondcolumn">
                <div className="contact-detail">
                    <div className="icon-container">
                        <div className="circle-container">
                            <img src={callus} alt="Call Us" />
                        </div>
                    </div>
                    <div className="text-container">
                        <p>Call Us</p>
                        <p>+14845219776</p>
                        <p>+14845219776</p>
                    </div>
                </div>

                <div className="contact-detail">
                    <div className="icon-container">
                        <div className="circle-container">
                            <img src={whatsapp} alt="WhatsApp" />
                        </div>
                    </div>
                    <div className="text-container">
                        <p>WhatsApp</p>
                        <p>+14845219776</p>
                        <p>+14845219776</p>
                    </div>
                </div>

                <div className="contact-detail">
                    <div className="icon-container">
                        <div className="circle-container">
                            <img src={emailimg} alt="Email Us" />
                        </div>
                    </div>
                    <div className="text-container">
                        <p>Email</p>
                        <p>support@umy.com</p>
                        <p>support@company.com</p>
                    </div>
                </div>

                <div className="contact-detail">
                    <div className="icon-container">
                        <div className="circle-container">
                            <img src={followusimg} alt="Follow Us" />
                        </div>
                    </div>
                    <div className="text-container">
                        <p>Follow Us</p>
                    </div>
                </div>

                <div className="contact-detail">
                    <div className="icon-container">
                        <div className="circle-container">
                            <img src={addressimg} alt="Address" />
                        </div>
                    </div>
                    <div className="text-container">
                        <p>Address</p>
                        <p>Door No. 1/190-C3,<br /> Ring Road Circle, Viraganur,<br /> Madurai, Tamil Nadu - 625009, India.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


            {/* SUBSCRIBE */}

            <div className="container subscribe_container">
    <div>
        <p className="subscribe_para">Subscribe to newsletter for</p>
        <p className="subscribe_para">getting updates</p>
    </div>

    <div className="row">
        <div className="col-12 col-sm-8 col-md-8 col-lg-8 p-0 d-flex">
            <input  type="text"  className="form-control subscribe_input"  placeholder=""  aria-label="Your placeholder text" value={email}  onChange={handleInputChange} />
        </div>
        <div className="col-12 col-sm-4 col-md-4 col-lg-4 p-0 d-flex">
            <button className="subscribe_button" type="button" onClick={handleSubmit}>
                Subscribe
            </button>
        </div>
        {message && <div className="message">{message}</div>}
    </div>
</div>



            <Footer />


        </div>

    );
};
export default Home;