import React, { useEffect, useState } from "react";
import { Getblogimage, baseUrl, Url, ModalPopupDelete, DeleteNotify, checkpermissionredirect } from './../Api.js';

import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Navbar from "../includes/Navbar";
import Pagetitle from "../includes/Pagetitle";

import Footer from "../includes/Footer";

const Myjobs = () => {
  const [jobList, setJobList] = useState([]);  
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [nPages, setnPages] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));

  
  const goToNextPage = () => {
    if (currentPage !== nPages) {
      setCurrentPage(currentPage + 1);
      GetJobs(currentPage + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      GetJobs(currentPage - 1);
    }
  };

  
  const goToPage = (value) => {
    setCurrentPage(value);
    GetJobs(value);
  };

  
  const GetJobs = async (value) => {
   value = value || currentPage;
 
   const postData = { admin: 1, currentpage: value, perpage: recordsPerPage };
 
   try {
     const response = await fetch(Url + "vendor-portal-jobs", {
       method: "POST",
       headers: {
         "Content-Type": "application/json",
       },
       body: JSON.stringify({ postdata: postData }),
     });
     const data = await response.json();
 
     if (data.success) {
       let jobData = data.data;
 
       setJobList(jobData); 
 
       if (data.count > 1) {
         setnPages(Math.ceil(data.count / recordsPerPage));
         setPageNumbers([...Array(Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1));
       }
     }
   } catch (error) {
     console.error("Error fetching jobs:", error);
   }
 };
 

  const DeleteConfirm = async (id) => {
    if (window.confirm("Are you sure?")) {
      const postData = { id };

      try {
        const response = await fetch(Url + "deletejob", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ postdata: postData }),
        });
        const data = await response.json();

        if (data.success) {
          DeleteNotify();
        } else {
          DeleteNotify(data.message);
        }

        GetJobs(currentPage);  
      } catch (error) {
        console.error("Error deleting job:", error);
      }
    }
  };

  const acceptJob = async (id) => {
    const postData = { id, status: 'accepted' };
    try {
      const response = await fetch(Url + "updatejobstatus", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ postdata: postData }),
      });
      const data = await response.json();
      if (data.success) {
        alert("Job Accepted");
        GetJobs(currentPage);
      } else {
        alert("Failed to accept job");
      }
    } catch (error) {
      console.error("Error accepting job:", error);
    }
  };

  const rejectJob = async (id) => {
    const postData = { id, status: 'rejected' };
    try {
      const response = await fetch(Url + "updatejobstatus", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ postdata: postData }),
      });
      const data = await response.json();
      if (data.success) {
        alert("Job Rejected");
        GetJobs(currentPage);
      } else {
        alert("Failed to reject job");
      }
    } catch (error) {
      console.error("Error rejecting job:", error);
    }
  };

  
  useEffect(() => {
    GetJobs(currentPage);
  }, [currentPage]);

  return (
    <div id="app">
      <Header title="My Jobs" />

      <Sidebar activePage="Myjobs" activeChildPage="List" />
      <div id="main">
        <Navbar />

        <div className="main-content container-fluid">
          <Pagetitle activePage="Myjobs" />

          <section className="section">
            <div className="card">
              <div className="card-header">
                <span>My Jobs</span>
              </div>
              <div className="card-body">
                <table className="table table-striped" id="table1">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Image</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  {jobList.length > 0 ? (
                    <tbody>
                      {jobList.map((job) => (
                        <tr key={job._id}>
                          <td>{job.title}</td>
                          <td>
                            <img src={job.image} className="img-responsive w-100px" alt={job.title} />
                          </td>
                          <td>
                            {job.status === 1 ? (
                              <span className="badge bg-success">Active</span>
                            ) : (
                              <span className="badge bg-danger">Inactive</span>
                            )}
                          </td>
                          <td className="table-action-sections">
                            <button onClick={() => acceptJob(job._id)} className="btn btn-success btn-sm">
                              Accept
                            </button>
                            <button onClick={() => rejectJob(job._id)} className="btn btn-warning btn-sm">
                              Reject
                            </button>
                            <button onClick={() => DeleteConfirm(job._id)} className="btn btn-danger btn-sm">
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="4" align="center">
                          No Records Found
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>

            {pageNumbers.length > 1 ? (
              <nav>
                <ul className="pagination pagination-primary justify-content-center">
                  <li className="page-item prev-paging-single">
                    <a className="page-link" onClick={goToPrevPage} href="#">
                      Previous
                    </a>
                  </li>
                  {pageNumbers.map((pgNumber) => (
                    <li
                      key={pgNumber}
                      className={`page-item ${currentPage === pgNumber ? "active" : ""}`}
                    >
                      <a onClick={() => goToPage(pgNumber)} className="page-link" href="#">
                        {pgNumber}
                      </a>
                    </li>
                  ))}
                  <li className="page-item paging-next-single">
                    <a className="page-link" onClick={goToNextPage} href="#">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            ) : (
              ""
            )}
            
          </section>

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Myjobs;
