import React, { useState } from "react";

import { userId, Url, CommonNotify, ProfilePictureOutput } from './../Api.js';

import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Navbar from "../includes/Navbar";

import Footer from "../includes/Footer";




const Profile = () => {



  const [Profilepicture, setProfilepicture] = useState({ preview: ProfilePictureOutput, data: '' });

  const [notification, setnotification] = useState("");
  const [notificationtype, setnotificationtype] = useState("");



  const Updateprofile = async (e) => {
    e.preventDefault();
    setnotification("");

    try {

      let formData = new FormData()
      formData.append('profilepicture', Profilepicture.data)
      formData.append('id', userId)


      const response = await fetch(Url + "update-profile", {
        method: "POST",
        body: formData

      }).then((response) => response.json())
        .then((data) => {
          if (data.success) {

            setnotificationtype('success')
            setnotification(data.message)
          } else {
            //   
          }


        })

    } catch (error) {
      //   
    }
  };

  const handlePictureChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    }
    setProfilepicture(img)
  }



  return (

    <div id="app">
      <Header title="My Profile" />
      <Sidebar />
      <div id="main">
        <Navbar />




      </div>

    </div>


  );
};
export default Profile;